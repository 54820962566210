// eslint-disable-next-line import/no-extraneous-dependencies
import { Notifier } from "@airbrake/browser";

const airbrake = new Notifier({
  projectId: 513828,
  projectKey: "11a30a6671a383d017c6abcada2048d2"
});

airbrake.addFilter(notice => {
  const { backtrace } = notice.errors[0];

  // We want to check the backtrace to ensure the error comes from our app and not an external source
  const isFromApp = backtrace.every(url => {
    return url.file.includes("/app/javascript");
  });

  if (!isFromApp) {
    return null;
  }

  return notice;
});
